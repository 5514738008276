import A from './A';
import Abbr from './Abbr';
import Address from './Address';
import Article from './Article';
import Aside from './Aside';
import B from './B';
import Br from './Br';
import Button from './Button';
import Cite from './Cite';
import Code from './Code';
import Data from './Data';
import Dd from './Dd';
import Details from './Details';
import Div from './Div';
import Dl from './Dl';
import Dt from './Dt';
import Em from './Em';
import Fieldset from './Fieldset';
import Footer from './Footer';
import Form from './Form';
import H1 from './H1';
import H2 from './H2';
import H3 from './H3';
import H4 from './H4';
import H5 from './H5';
import H6 from './H6';
import Header from './Header';
import Hr from './Hr';
import I from './I';
import Img from './Img';
import Input from './Input';
import InputCheckbox from './InputCheckbox';
import InputEmail from './InputEmail';
import InputFile from './InputFile';
import InputNumber from './InputNumber';
import InputPassword from './InputPassword';
import InputRadio from './InputRadio';
import InputSearch from './InputSearch';
import InputSubmit from './InputSubmit';
import InputTelephone from './InputTelephone';
import InputText from './InputText';
import InputUrl from './InputUrl';
import Kbd from './Kbd';
import Label from './Label';
import Legend from './Legend';
import Li from './Li';
import Main from './Main';
import Nav from './Nav';
import Ol from './Ol';
import P from './P';
import Path from './Path';
import Pre from './Pre';
import Progress from './Progress';
import Q from './Q';
import S from './S';
import Samp from './Samp';
import Section from './Section';
import Select from './Select';
import Small from './Small';
import Span from './Span';
import Strong from './Strong';
import Sub from './Sub';
import Summary from './Summary';
import Sup from './Sup';
import Svg from './Svg';
import Table from './Table';
import Tbody from './Tbody';
import Td from './Td';
import Textarea from './Textarea';
import Tfoot from './Tfoot';
import Th from './Th';
import Thead from './Thead';
import Time from './Time';
import Tr from './Tr';
import U from './U';
import Ul from './Ul';
import Var from './Var';

export {
  A,
  Abbr,
  Address,
  Article,
  Aside,
  B,
  Br,
  Button,
  Cite,
  Code,
  Data,
  Dd,
  Details,
  Div,
  Dl,
  Dt,
  Em,
  Fieldset,
  Footer,
  Form,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Header,
  Hr,
  I,
  Img,
  Input,
  InputCheckbox,
  InputEmail,
  InputFile,
  InputNumber,
  InputPassword,
  InputRadio,
  InputSearch,
  InputSubmit,
  InputTelephone,
  InputText,
  InputUrl,
  Kbd,
  Label,
  Legend,
  Li,
  Main,
  Nav,
  Ol,
  P,
  Path,
  Pre,
  Progress,
  Q,
  S,
  Samp,
  Section,
  Select,
  Small,
  Span,
  Strong,
  Sub,
  Summary,
  Sup,
  Svg,
  Table,
  Tbody,
  Td,
  Textarea,
  Tfoot,
  Th,
  Thead,
  Time,
  Tr,
  U,
  Ul,
  Var,
};
